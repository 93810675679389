import React from 'react'
import { DayTimer } from "../components/DayTimer";

import { ImgWithFallback } from '../components/ImgWithFallback';

import './landingPage.css';
import DAYS_LEFT from '../graphics/21-18436-SPLK-o11y-game-background-assets-SVG/days-left.svg';
import COVER_png from '../graphics/landing_page-cut.png';
import COVER_wpack from '../graphics/landing_page-cut.webp';


export default function LandingPageFree(props) {
    return (
        <div className="landing-backgroung">
            <div className="central-block">

                <div className="cover-img-cont">
                    <ImgWithFallback src={COVER_wpack} fallback={COVER_png} alt="" />

                    {/* <img id="" src={COVER} /> */}
                </div>


                <div className="container h-100 lp-left-col">
                    <div className="row h-100">
                        <div className="col-4">
                            <div className="row">
                                <div className="play-button pulse" onClick={() => props.play()}></div>
                                <div className="days-counter">
                                    {/* < DayTimer /> */}<br />

                                </div>
                                <div className="days-left-container">
                                    {/* <img src={DAYS_LEFT} alt=""></img> */}
                                    <h3>UNLIMITED</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
