import React from 'react';

import BUTTON from '../graphics/200x50-button.svg';
let textSetup = require('../gameSetup.json');

export function LevelUp(props) {

    return (
        <>
            <div className="container question-container">
                <div className="row h-100 align-items-center">
                    <div className="col-8 mx-auto mt-5">
                       {textSetup.levelup && textSetup.levelup.split('\n').map((item, i) => { return <p key={i}>{item}</p>; })}

                        <div className="row justify-content-md-center">
                            <div className="col col-md-6" align="center">
                                <div onClick={() => { props.startEpisodeTwo() }} className="start-the-game-button zoom">
                                    <img src={BUTTON} alt="start"></img>
                                    <span id="start">EPISODE 2</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}