import React from 'react';
import ReactPlayer from "react-player";
import Modal from "react-modal";


import { WelcomePage } from '../pages/welcomePage';
import { Questions1 } from '../components/Questions';
import { Answers1 } from '../components/Answers';
import { LevelUp } from "../pages/levelUp";
import Stress from '../components/Stress';
import { StickersLeft, StickersRight, StickersTop } from "../components/LeftStickers";
import { ImgWithFallback } from '../components/ImgWithFallback';


import { Event } from "../components/Tracking";

import LOGO_png from "../graphics/logo_full-color.png";
import LOGO_webp from "../graphics/logo_full-color.png";


import CLOSE from '../graphics/x-button-gray.svg';
import OLDLEFT_png from '../graphics/background-old-computer-left.png';
import OLDLEFT_webp from '../graphics/background-old-computer-left.webp';

import OLDRIGHT_png from '../graphics/background-old-computer-right.png';
import OLDRIGHT_webp from '../graphics/background-old-computer-right.webp';



Modal.setAppElement("#root");


let questionsJSON = require('../episode1.json');


export default class EpisodeOne extends React.Component {

    state = {
        welcomePage: true,
        episode1Quations: false,
        levelUp: false,
        questions: '',
        questionObj: "",
        questionOptions: "",
        questionId: "INTRO",
        stickers: ["sticker"],
        video: false,
        width: "",
        height: ""

    }


    componentDidMount() {
        // upload all questions to state
        this.setState({
            questions: questionsJSON
        });

        // shuffle options order in json file 
        // this.props.shuffleOptions(questionsJSON);

        // set windows dimentions (for video player)
        this.getWindowDimensions();

        // start a timer for video autoplay
        setTimeout(function () {
            this.setState({ video: true }) //After 1 second, set video to true
        }.bind(this), 1000)
    };

    shuffle = (questionsArray) => {
        questionsArray.forEach((question) => {
            this.props.shuffleAnswers(question)
        })
    }

    getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        this.setState({
            width: width,
            height: height
        })
    }

    // switch the current question to the next one
    selectAnswer = async (answer) => {
        let id = answer['next'];
        let nextQuestionObj = await this.state.questions.find(obj => obj.id === id);

        // check is user get a sticker
        if (nextQuestionObj.sticker) {
            // check if the sticker is in state already:
            if (!this.state.stickers.includes(nextQuestionObj.sticker)) {
                const newSticker = nextQuestionObj.sticker;
                const currentStickers = this.state.stickers;
                currentStickers.push(newSticker);
                this.setState({
                    stickers: currentStickers,
                })
            }
        }

        // report to Google Analytics
        Event(`Answer Flow${this.props.specialTag}`, `User moved to question ${id}${this.props.specialTag}`);

        // update the State
        this.setState({
            questionId: id,
            questionObj: nextQuestionObj
        });

        // check if the Episode 1  is over
        if (id === 'WIN3') {
            Event(`Game Transition${this.props.specialTag}`, `User finished EPISODE 1${this.props.specialTag}`);

            this.setState({
                episode1Quations: false,
                levelUp: true
            });
        }
    };

    // Go to the first question of the first episode
    startTheGame = () => {
        // report to Google Analytics
        Event(`Game Transition${this.props.specialTag}`, `User started EPISODE 1${this.props.specialTag}`);

        // this.props.shuffleAnswers(this.state.questions[0]);

        this.setState({
            welcomePage: false,
            episode1Quations: true,
            pauseStress: false,
            questionObj: this.state.questions[0],
            // questionOptions: this.shuffleAnswers(this.state.questions[0])
        })

        this.props.toggleMusic();
    };

    toggleVideoModal = () => {
        this.setState({
            video: !this.state.video
        })
    }

    render() {
        return (
            <>
                <div className="row flex-nowrap p-0 m-0 special-width">
                    <div className="col-5 screen-left mt-5 p-0">
                        <div className="img-cont-old-left">
                            <ImgWithFallback id='img-old-left' src={OLDLEFT_webp} fallback={OLDLEFT_png} alt="" />
                        </div>
                        <div className="container-fixed">
                            <div className="row justify-content-right">
                                <div className="col-10 min-col">

                                    <ImgWithFallback id='logo' src={LOGO_webp} fallback={LOGO_png} alt="" />

                                    {/* <img id="logo" src={Logo} alt="O11y Quest" /> */}
                                </div>
                                <div className="col-1 stickers-col p-0 ">
                                    {this.state.stickers.length ? <StickersLeft stickers={this.state.stickers} /> : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="screen-right mt-5 p-0">
                        <div className="img-cont-old-right">
                            <ImgWithFallback id='img-old-right' src={OLDRIGHT_webp} fallback={OLDRIGHT_png} alt="" />
                        </div>
                        <div className="container-fixed p-0 special-width2">
                            <div className="row p-0 ">
                                <div className="stickers-row">
                                    {this.state.stickers.length > 6 ? <StickersTop stickers={this.state.stickers} /> : <></>}
                                </div>
                                <div className="col terminal p-0 ">

                                    <div className="scroll">
                                        {/* <div className="stickers-row">
                                            {this.state.stickers.length > 6 ? <StickersTop stickers={this.state.stickers} /> : <></>}
                                        </div> */}
                                        {this.state.welcomePage &&
                                            <WelcomePage
                                                startTheGame={this.startTheGame}
                                                tShirts={this.props.tShirts}
                                                prize={this.props.prize}
                                            />
                                        }
                                        {this.state.episode1Quations &&
                                            <>
                                                <Questions1 question={this.state.questionObj} />
                                                <div className="container mt-2">
                                                    <div className="row p-0">
                                                        <div className="col-8 p-0">
                                                            <Answers1 question={this.state.questionObj} selectAnswer={this.selectAnswer} />

                                                        </div>
                                                        <div className="col-4 p-0 d-flex justify-content-center">
                                                            <span>Your Stress <span className="stress-points">
                                                                <Stress question={this.state.questionObj} updateStress={this.props.updateStress} stress={this.props.stress} /></span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {this.state.levelUp &&
                                            <LevelUp
                                                question={this.state.questionObj}
                                                selectAnswer={this.selectAnswer}
                                                startEpisodeTwo={this.props.startEpisodeTwo} />}

                                    </div>
                                </div>
                                <div className="col col-right">
                                    {this.state.stickers.length > 3 ? <StickersRight stickers={this.state.stickers} /> : <></>}

                                </div>

                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.video}
                        onRequestClose={this.toggleVideoModal}
                        contentLabel="My dialog"
                        className="mymodal-video"
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <div className="scroll">
                            <div className="d-flex justify-content-end" >
                                <img id="close-video-btn" className="close-btn zoom" onClick={this.toggleVideoModal} src={CLOSE} alt="close" />
                            </div>
                            <div className="d-flex justify-content-center ">

                                <ReactPlayer
                                    url="https://www.youtube.com/embed/RKK1kWfo2wo?controls=0"
                                    playing={true}
                                    width={this.state.width * 0.594}
                                    height={this.state.height * 0.7}
                                    volume={0.3}
                                />
                            </div>

                        </div>
                    </Modal>

                </div>

                {/* </div> */}
            </>
        )

    }
}



