import React from 'react';

import { Event } from "./Tracking";

export const Questions1 = React.memo((props) => {
    const abcList = ["A", "B", "C", "D", "E", "F", "G"];

    return (
        <>
            <div className="col-md-12 mb-3 mt-3 ">
                <div className="question-container">
                    <div className="scroll">
                        {/* <h3>{props.question && props.question.title}</h3> */}
                        <div className="question">{props.question && props.question.question.split('\n').map((item, i) => { return <p key={i}>{item}</p>; })}
                        </div>
                        <div >
                            {props.question && props.question.options.map((option, i) => {
                                return (
                                    <div key={i} className="row">
                                        <div className="col-10">
                                            <span>{abcList[i]} {'>'}{'>'} {option.name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export const Questions2 = React.memo((props) => {

    const abcList = ["A", "B", "C", "D", "E", "F", "G"];

    return (
        <>
            <div className="col-md-12 mb-3 mt-3 ">
                <div className="question-container2">
                    <div className="scroll">

                        {/* <h3>{props.question && props.question.title}</h3> */}
                        <div className="question">{props.question && props.question.question.split('\n').map((item, i) => { return <p key={i}>{item}</p>; })}
                        </div>
                        <div >
                            {props.question && props.question.options.map((option, i) => {
                                return (
                                    <div key={i} className="row">
                                        <div className="col">
                                            <span>{abcList[i]} {'>'}{'>'} {option.name}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
})


export const FinalQuestion = React.memo((props) => {


    return (
        <>
            <div className="col-md-12 mb-3 mt-3 ">
                <div className="question-container2">
                    <div className="scroll">

                        {/* <h3>{props.question && props.question.title}</h3> */}
                        <div className="question">
                            <p>Now that you’ve experienced how Splunk Observability Cloud can help you in real-world situations, we’d love for you to try it in your real world situations. First, though, click this link to get your second sweepstakes entry: </p>
                            <p onClick={() => { Event(`Marketing${props.specialTag}`, `User clicked FREE TRIAL${props.specialTag}`); }}>A {'>'}{'>'}<span> </span>
                                <a
                                    href="https://www.splunk.com/en_us/observability/o11y-cloud-free-trial.html?utm_medium=email&utm_source=splunk&utm_campaign=AMER_FY22Q2_AMER_DGN_OTH_Dev_EN_Quest_for_O11y_Game_May21&utm_content=O11y_Cloud_Trial"
                                    target="_blank"
                                    rel="noreferrer"
                                >Get a free trial of the Observability Suite</a>
                            </p>
                            <p onClick={() => { Event(`Marketing${props.specialTag}`, `User clicked BEGINNER'S GUIDE${props.specialTag}`); }}>B {'>'}{'>'}<span> </span>
                                <a
                                    href="https://www.splunk.com/en_us/form/beginners-guide-to-observability.html?utm_medium=email&utm_source=splunk&utm_campaign=AMER_FY22Q2_AMER_DGN_OTH_Dev_EN_Quest_for_O11y_Game_May21&utm_content=Beg_Guide_O11y_EB"
                                    target="_blank"
                                    rel="noreferrer"
                                >Read the Beginner’s Guide to Observability</a></p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
})
