import ReactGA from "react-ga";

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID);
}

export const PageView = () => {
    ReactGA.pageview(window.location.pathname +
        window.location.search);
}

/**
 * Event - Add custom tracking event.
 * @param {string} action 
 */
export const Event = (category, action) => {
    ReactGA.event({
        category: category,
        action: action,
    });
};