
import React from 'react';


import BUTTON from '../graphics/button.svg';
import BUTTON2 from '../graphics/70x40-button.svg';




const Answer1 = (props) => {
    // for marking the answears 
    const abcList = ["A", "B", "C", "D", "E", "F", "G"];

    return (
        <>
            <div className="option-button-container zoom" onClick={() => { props.selectAnswer(props.option) }}>
                <img src={BUTTON} alt="" />
                <span className="option-button">{abcList[props.i]}</span>
            </div>
        </>
    )
}

export const Answers1 = (props) => {

    return (
        <>
            {props.question && props.question.options.map((option, i) => {
                return (
                    <Answer1 key={i.toString()} option={option} selectAnswer={props.selectAnswer} i={i} />
                )
            })}
        </>

    )
}


// EPISODE 2

const Answer2 = (props) => {
    // for marking the answears 
    const abcList = ["A", "B", "C", "D", "E", "F", "G"];

    return (
        <>
            <div className="option-button-container2 zoom" onClick={() => { props.selectAnswer(props.option) }}>
                <img src={BUTTON2} alt="" />
                <span className="option-button">{abcList[props.i]}</span>
            </div>
        </>
    )
}

export const Answers2 = (props) => {

    return (
        <>
            {props.question && props.question.options.map((option, i) => {
                return (
                    <Answer2 key={i.toString()} option={option} selectAnswer={props.selectAnswer} i={i} />
                )
            })}
        </>

    )
}