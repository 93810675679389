import React from 'react';


const getDayNow = () => {

    try {
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let today = new Date();
        let lastDay = new Date(process.env.REACT_APP_FINAL_DAY);
        const diffDays = Math.round((lastDay - today) / oneDay);
        if (diffDays > 1) {
            return `${diffDays}`
        } else {
            return 0
        }
    } catch (err) {
        console.log(err.message)
    }


}

export const DayTimer = () => {

    return (
        <>
            <span>00000{getDayNow()}</span>
        </>
    )

}

