import React, { Component } from 'react';
import { Questions2, FinalQuestion } from '../components/Questions';

import { Answers2 } from '../components/Answers';
// import { StickersAll } from "../components/LeftStickers";
import Stress from '../components/Stress';
import { Finish } from '../components/finish';
import { Event } from "../components/Tracking";
import { ImgWithFallback } from '../components/ImgWithFallback';

import LOGO_png from "../graphics/logo_full-color.png";
import LOGO_webp from "../graphics/logo_full-color.webp";
import ICONS from "../graphics/dockicons.svg";
import NEWLEFT_png from '../graphics/episode2-left.png';
import NEWLEFT_webp from '../graphics/episode2-left.webp';

import NEWRIGHT_png from '../graphics/episode2-right.png';
import NEWRIGHT_webp from '../graphics/episode2-right.webp';


let questionsJSON = require('../episode2.json');


export default class episodeTwo extends Component {

    state = {
        welcomePage: true,
        episode1Quations: false,
        finish: false,
        questions: '',
        questionObj: "",
        questionId: "2-START",
        stickers: ["gloves", "hammer", "hatchet", "lantern", "whistle", "sunglasses"]
    }

    componentDidMount() {
        // this.props.shuffleOptions(questionsJSON);

        // upload all questions to state
        this.setState({
            questions: questionsJSON,
            questionObj: questionsJSON[0]

        });
    };

    // switch the current question to the next one
    selectAnswer = async (answer) => {
        let id = answer['next'];
        let nextQuestionObj = await this.state.questions.find(obj => obj.id === id);

        // check is user get a sticker
        if (nextQuestionObj.sticker) {
            // check if the sticker is in state already:
            if (!this.state.stickers.includes(nextQuestionObj.sticker)) {
                const newSticker = nextQuestionObj.sticker;
                console.log(`user got a sticker ${newSticker}`);
                const currentStickers = this.state.stickers;
                console.log(`currentStickers: ===>> ${currentStickers}`)
                currentStickers.push(newSticker);
                console.log(currentStickers);
                this.setState({
                    stickers: currentStickers,
                })
            }
        }

        // report to Google Analytics
        Event(`Answer Flow${this.props.specialTag}`, `User moved to question ${id}${this.props.specialTag}`);

        // update the State
        this.setState({
            questionId: id,
            questionObj: nextQuestionObj
        });

        // check if the Episode 1  is over
        if (id === '2-END') {
            Event(`Game Transition${this.props.specialTag}`, `User finished EPISODE 2${this.props.specialTag}`);

            this.setState({
                finish: true
            });
        }
    };

    render() {
        return (
            <>
                {/* <div className="container-fixed"> */}
                <div className="row min-width p-0 m-0 flex-nowrap h-100">
                    <div className="col-5 screen2-left mt-5 p-0 ">

                        <div className=" container-fixed img-cont-new-left ">
                            <ImgWithFallback id='img-new-left' src={NEWLEFT_webp} fallback={NEWLEFT_png} alt="" />
                        </div>

                        <div className="container-fixed justify-content-end ">
                            <div className="row justify-content-end ">
                                {/* logo */}
                                <div className="col-10">
                                    <ImgWithFallback id='logo-small' src={LOGO_webp} fallback={LOGO_png} alt="" />
                                </div>
                                <div className="row justify-content-end">
                                    <div className="col-9 terminal2">
                                        {this.state.finish ?
                                            <FinalQuestion specialTag={this.props.specialTag} /> :
                                            <Questions2 question={this.state.questionObj} />
                                        }
                                        <div className="container mt-2 padding-left-5">
                                            <div className="row p-0">
                                                <div className="col-8 p-0">
                                                    <Answers2 question={this.state.questionObj} selectAnswer={this.selectAnswer} />
                                                </div>
                                                <div className="col-4 p-0">
                                                    <span>Your Stress <span className="stress-points"><Stress question={this.state.questionObj} updateStress={this.props.updateStress} stress={this.props.stress} /></span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="screen2-right mt-5 p-0">

                        <div className="img-cont-new-right">
                            <ImgWithFallback id='img-new-right' src={NEWRIGHT_webp} fallback={NEWRIGHT_png} alt="" />
                        </div>

                        <div className="containe-fixed p-0">
                            <div className="row w-90">
                                <div className="col browser">
                                    <div className="screenshot-container">

                                        {this.state.finish && this.props.username ?
                                            <Finish
                                                tShirts={this.props.tShirts}
                                                stress={this.props.stress}
                                                username={this.props.username}
                                                sessionId={this.props.sessionId}
                                                userPlace={this.props.userPlace}
                                                prize={this.props.prize}
                                                specialTag={this.props.specialTag} />
                                            :
                                            <img src={this.state.questionObj.img} alt="" />}
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row docpanel">
                                    <img id="dockpanel" src={ICONS} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </>
        )
    }
}
