import React from 'react'



import gloves from "../graphics/stickers/gloves.png";
import hatchet from "../graphics/stickers/hatchet.png";
import lantern from "../graphics/stickers/lantern.png";
import sunglasses from "../graphics/stickers/sunglasses.png";
import whistle from "../graphics/stickers/whistle.png";
import vacation from "../graphics/stickers/vacation.png";
import sticker from "../graphics/stickers/sticker.png";
import tshirt from "../graphics/stickers/tshirt.png";
import hammer from "../graphics/stickers/hammer.png";


export const stickersDict = {
    gloves: gloves,
    hatchet: hatchet,
    lantern: lantern,
    sunglasses: sunglasses,
    whistle: whistle,
    vacation: vacation,
    sticker: sticker,
    tshirt: tshirt,
    hammer: hammer
}

export const StickersLeft =  (props) => {
    const stickerList = props.stickers.slice(0, 3);
    let leftBlock = stickerList.map((sticker, index) => {
        return (
            <Sticker key={index} stickerName={sticker}/>
        )
    })
    return (
        <>
           {leftBlock}
        </>
    )
}

export const StickersRight =  (props) => {
    const stickerList = props.stickers.slice(3, 6);
    let rightBlock = stickerList.map((sticker, index) => {
        return (
            <Sticker key={index} stickerName={sticker}/>
        )
    })
    return (
        <>
           {rightBlock}
        </>
    )
}

export const StickersTop =  (props) => {
    const stickerList = props.stickers.slice(6, 9);
    let rightBlock = stickerList.map((sticker, index) => {
        return (
            <Sticker2 key={index} stickerName={sticker}/>
        )
    })
    return (
        <>
           {rightBlock}
        </>
    )
}

export const Sticker = (props) => {
    return (
        <>
            <div className="row sticker-container">
                <img src={stickersDict[props.stickerName]} alt={props.stickerName}/>
            </div>
        </>
    )
}

export const Sticker2 = (props) => {
    return (
        <>
            <div className="col sticker-container-top">
                <img src={stickersDict[props.stickerName]} alt={props.stickerName}/>
            </div>
        </>
    )
}

export const Sticker3 = (props) => {
    return (
        <>
            <div className="col sticker-container-docs">
                <img src={stickersDict[props.stickerName]} alt={props.stickerName}/>
            </div>
        </>
    )
}

export const StickersAll = (props) => {
    const stickerList = props.stickers;
    let horizontalBlock = stickerList.map((sticker, index) => {
        return (
            <Sticker3 key={index} stickerName={sticker}/>
        )
    })
    return (
        <>
           {horizontalBlock}
        </>
    )
}