import React, { useState, useEffect } from 'react';


import SPLUNKLOGO from '../graphics/splunk-logo-white.png';
import BACKGROUND from "../graphics/background_blank.jpg";



export const RulesText = (props) => {
    const [text, setText] = useState("");

    useEffect(() => {

        fetch('../rules.txt')
            .then(r => r.text())
            .then(setText)
    }, [])

    let textFormated = text.split('\n').map((item, i) => { return <p key={i}>{item}</p>; })

    return textFormated

}


export default function Rules() {
    return (
        <div className="rules-background ">
            <div className="episode-backgroung-img-cont">
                <img id="" src={BACKGROUND} />
            </div>
            <div className="rules-block">

                <div className="mobile-logo-container">
                    <img id="logo-splunk" src={SPLUNKLOGO} alt="Splunk" />

                </div>
                <div className="rules-text">
                    <RulesText />
                </div>
            </div>


        </div>
    )
}
