import React from 'react';
import Logo from "../graphics/logo_full-color.png";
import { Event } from "../components/Tracking";


import {
    EmailShareButton,

} from "react-share";

import EmailIcon from '../graphics/mail-icon.svg'

export default function EmailButton() {
    return (
        <span onClick={() => { Event("Share", "User clicked on Email share button"); }}>
            <EmailShareButton
                url={"Try this O11yQuest on your Desktop browser-->> https://o11yquest.splunk.com"}
                quote="Try this O11yQuest on your Desktop browser"
                hashtag="#splunk"
            >

                <img className="social-icon" src={EmailIcon} alt=""/>
            </EmailShareButton>
        </span>
    )
}


export function MobileScreen() {

    return (
        <>
            <div className="d-flex align-items-center h-100">
                <div className="w-100">
                    <div className="d-flex justify-content-center">
                        <img id="mobile-logo" src={Logo} alt="logo"></img>

                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <div className="text-center mobile-text-block">
                            <p>Start your adventure at </p>
                            <h5> https://o11yquest.splunk.com </h5>
                            <p>on your desktop browser</p>
                            <br></br>
                            <p>or email link to get started</p>
                            <EmailButton
                                source="https://o11yquest.splunk.com"
                            />

                        </div>
                    </div>
                </div>

            </div>

            {/* <table>
                <tbody>
                    <tr>
                        <td className="align-middle">Start your adventure at https://o11yquest.splunk.com on your desktop browser,</td>
                    </tr>
                    <tr>
                    <td>or email link to get started.</td>

                    </tr>
                </tbody>
            </table> */}

        </>
    )
}