import React from 'react';



export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: { message: "", stack: "" },
            info: { componentStack: "" }
        };

    }
    static getDerivedStateFromError = error => {
        return { hasError: true };
    };

    componentDidCatch(error, info) {
        // Display fallback UI
        // this.setState({ hasError: true });
        this.setState({ error, info });

        // You can also log the error to an error reporting service
        console.log(error, info)
        // logErrorToMyService(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return this.props.children;
    }
}