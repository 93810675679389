import React, { useState } from 'react'
import Logo from "../graphics/logo_full-color.png";
import { ImgWithFallback } from '../components/ImgWithFallback';
import Dropdown from 'react-dropdown';

import BACKGROUND_webp from "../graphics/background_blank.webp";
import BACKGROUND_jpg from "../graphics/background_blank.jpg";

import './landingPage.css';

const options = [
    { value: 'Denmark', label: 'Denmark', className: 'dropd-option', key: "1" },
    { value: 'Ireland', label: 'Ireland', className: 'dropd-option', key: "2" },
    { value: 'The Netherland', label: 'The Netherlands', className: 'dropd-option' },
    { value: 'Norway', label: 'Norway', className: 'dropd-option' },
    { value: 'Sweden', label: 'Sweden', className: 'dropd-option' },
    { value: 'Switzerland', label: 'Switzerland', className: 'dropd-option' },
    { value: 'The United Kingdom', label: 'The United Kingdom', className: 'dropd-option' },

    { value: 'world', label: 'Other', className: 'dropd-option other' },
];


export default function LandingPage(props) {

    const [region, setRegion] = useState('');

    return (
        <div>
            <div className="landing-background">
                <div className="episode-backgroung-img-cont">
                    <ImgWithFallback src={BACKGROUND_webp} fallback={BACKGROUND_jpg} alt="" />

                </div>
                <div className="central-block3">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div>
                            <div className="d-flex justify-content-center">
                                <img id="logo-big" src={Logo} alt="" />
                            </div>
                            <div className="w-50 d-flex justify-content-center mt-3 m-auto text-center" >
                                <Dropdown className="dropdown" options={options} onChange={(e) => { props.setRegion(e.value); setRegion(e.value) }} placeholder="Select your region" />

                            </div>
                            <div className="w-50 d-flex justify-content-center mt-3 m-auto text-center" >

                                {region && <div className="play-button2 pulse" onClick={() => props.play()}></div>}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
