import React from 'react';

import BUTTON from '../graphics/200x50-button.svg';


export const tShirts = (gameText) => {
    console.log("we have T-shirt!");
    gameText.welcome1.split('\n').map((item, i) => {
        return <p key={i}>{item}</p>;
    })
}
export const noTShirts = (gameText) => {
    gameText.welcome2.split('\n').map((item, i) => {
        return <p key={i}>{item}</p>;
    })
}


export const WelcomePage = (props) => {

    let gameText = '';
    if (props.prize) {
        gameText = require('../gameSetupPrize.json');
    } else {
        gameText = require('../gameSetupFree.json');
    }

    return (
        <>
            <div className="container-sm p-0 ">

                <div className="row ">
                    <div className="col-lg-12 mb-3 mt-3 ">

                        {props.prize ?
                            <div className="welcome-text-block">
                                <div className="scroll">
                                    {props.tShirts ?
                                        gameText.welcome1.split('\n').map((item, i) => {
                                            return <p key={i}>{item}</p>;
                                        }) :
                                        gameText.welcome2.split('\n').map((item, i) => {
                                            return <p key={i}>{item}</p>;
                                        })
                                    }
                                </div>
                            </div>
                            :
                            <div className="welcome-text-block text-center mt-5">
                                <div className="scroll">
                                    {props.tShirts ?
                                        gameText.welcome1.split('\n').map((item, i) => {
                                            return <p key={i}>{item}</p>;
                                        }) :
                                        gameText.welcome2.split('\n').map((item, i) => {
                                            return <p key={i}>{item}</p>;
                                        })
                                    }
                                </div>
                            </div>
                        }


                        {/* <div className="welcome-text-block text-center mt-5">
                            <div className="scroll">
                                {props.tShirts ?
                                    gameText.welcome1.split('\n').map((item, i) => {
                                        return <p key={i}>{item}</p>;
                                    }) :
                                    gameText.welcome2.split('\n').map((item, i) => {
                                        return <p key={i}>{item}</p>;
                                    })
                                }
                            </div>
                        </div> */}

                        <div className="row justify-content-md-center m-0">
                            <div className="col col-md-4">
                                <div onClick={() => { props.startTheGame() }} className="start-the-game-button mt-1 zoom">
                                    <img src={BUTTON} alt="start"></img>
                                    <span id="start">START</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}