import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import './App.css';
import { ErrorBoundary } from './components/ErrorBoundary';
import Navbar from './components/Navbar';
import { MobileScreen } from './pages/mobileScreen';
import { Event } from "./components/Tracking";
import LandingPage from "./pages/landingPage";
import LandingPageFree from "./pages/landingPage2";

import EpisodeOne from "./pages/episodeOne";
import EpisodeTwo from "./pages/episodeTwo";
import { ImgWithFallback } from './components/ImgWithFallback';

import CookieConsent from "react-cookie-consent";
import { PageView, initGA } from './components/Tracking';
import axios from 'axios';

import GRADIENT from "./graphics/header-gradient.svg";
import MUSIC from './music/47_short3_8-bits-racing-car-game-theme_0060.mp3';
import BACKGROUND_jpg from './graphics/background_blank.jpg';
import BACKGROUND_webp from './graphics/background_blank.webp';


const audio = new Audio(MUSIC);

class App extends Component {

    state = {
        landingPage: true,
        episode1: false,
        episode2: false,
        stress: 0,
        pauseStress: true,
        username: '',
        sessionId: "",
        tShirts: true,
        error: '',
        errorInfo: '',
        showScoreTable: false,
        redirect: false,
        music: false,
        userPlace: '',
        gameIsOver: false,
        region: '',
        prize: '',
        specialTag: ''
    }

    toggleModal = () => {
        this.setState({
            showScoreTable: !this.state.showScoreTable
        });
    }

    componentDidMount() {
        // initialize Google Analitycs 
        initGA(process.env.REACT_APP_GOOGLE_MEASURMENT_ID);
        PageView();


        // check if the game is still active
        this.checkGameTime();

        // check if we still have T-shirt as a prize
        this.checkThePrize();

        // set a local storage var for stress
        localStorage.setItem('stress', 0);

    };

    checkGameTime = () => {
        try {
            let now = new Date();
            let startDay = new Date(process.env.REACT_APP_START_DAY);
            let lastDay = new Date(process.env.REACT_APP_FINAL_DAY);

            if (now > startDay && now < lastDay) {
                // do nothing
            } else {
                // prize game is over
                this.setState({
                    gameIsOver: true
                })
            }

        } catch (err) {
            console.log(err.message)
        }
    }

    toggleMusic = () => {
        this.setState({
            music: !this.state.music
        }, () => this.playMusic());
    }

    playMusic = () => {
        //turn ON/OFF music
        this.state.music ? audio.play() : audio.pause();
        audio.loop = true;
        audio.volume = 0.1;
    }

    // play fuction redirect user to the Welcome page
    play = () => {
        // report to Google Analytics
        Event(`Game Transition${this.state.specialTag}`, `User clicked the PLAY button${this.state.specialTag}`);
        this.setState({
            landingPage: false,
            episode1: true,
        })
    }

    setRegion = (region) => {
        // ckeck if region eligible for prize
        let specialTag = '';

        if (region !== 'world') {
            specialTag = " (eu)"
        }
        let prize = region !== 'world';
        this.setState({
            region: region,
            prize: prize,
            specialTag: specialTag
        })
        Event(`Game Transition${specialTag}`, `User selected region ${region}`)



    }

    saveScoreToDb = () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_KEY
        };

        axios
            .post(`api/v1/scores/add?score=${this.state.stress}`, {}, { headers: headers })
            .then((res) => {
                this.setState({
                    username: res.data.username,
                    sessionId: res.data.session_id,
                    stress: res.data.score,
                    userPlace: res.data.userPlace
                })
            })
    };

    // API call to BD to ckeck how many people already claimed their prizes.
    checkThePrize = async () => {
        // get the number of claimed prizes from DB
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_KEY
        };
        axios
            .get(`api/v1/prizes/`, { headers: headers })
            .then((res) => {

                // compare a number of claimed prises to the total number of t-shirts
                let priseCount = Number(res.data.prize_count);

                // console.log(priseCount);
                let totalTshirts = Number(process.env.REACT_APP_TSHIRT_COUNT);

                if (priseCount < totalTshirts) {
                    this.setState({
                        tShirts: true
                    })
                } else {
                    this.setState({
                        tShirts: false
                    })
                }
            })
    }

    updateStress = (stress, index) => {
        this.setState({
            stress: stress
        }, () => {
            if (index === "2-END") {
                this.saveScoreToDb();
            }
        })
    }

    startEpisodeTwo = () => {
        // report to Google Analytics
        Event(`Game Transition${this.state.specialTag}`, `User started EPISODE 2${this.state.specialTag}`);
        this.setState({
            episode1: false,
            episode2: true
        })
    }

    // shuffleAnswers = (questionObj) => {
    //     const options = questionObj.options;
    //     console.log(options);
    //     for (let i = options.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         [options[i], options[j]] = [options[j], options[i]];
    //     }
    // }


    shuffleOptions = (questionsArray) => {
        questionsArray.forEach((question) => {

            const options = question.options;
            for (let i = options.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [options[i], options[j]] = [options[j], options[i]];
            }
        })
    }

    loadLandingPage = () => {
        if (this.state.landingPage && this.state.gameIsOver) {
            return <LandingPageFree play={this.play} />
        } else if (this.state.landingPage) {
            return <LandingPage setRegion={this.setRegion} play={this.play} />
        } else return <></>
    }

    render() {
        return (
            <>
                <div className="desktop min-width  ">


                    <ErrorBoundary>

                        <CookieConsent
                            location="bottom"
                            buttonText="I Understand"
                            cookieName="myAwesomeCookieName2"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#e86609", fontSize: "13px" }}
                            expires={150}
                            enableDeclineButton
                            onDecline={() => {
                                // document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";;
                                this.setState({ redirect: true });
                            }}
                        >
                            This website uses cookies to enhance the user experience.{" "}
                        </CookieConsent>


                        {this.state.redirect ? (<Redirect push to="/splunk" />) : null}

                        <div className="container-fixed  episode-background min-width p-0 m-0">

                            <div className="episode-backgroung-img-cont">
                                <ImgWithFallback src={BACKGROUND_webp} fallback={BACKGROUND_jpg} alt="" />
                            </div>

                            <div id="gradient">
                                <img src={GRADIENT} alt="" />
                            </div>

                            {this.state.landingPage && this.loadLandingPage()}


                            {this.state.landingPage === false ? <Navbar
                                music={this.state.music}
                                toggleMusic={this.toggleMusic}
                                prize={this.state.prize} />
                                : <></>}

                            {this.state.episode1 &&
                                <EpisodeOne
                                    tShirts={this.state.tShirts}
                                    stress={this.state.stress}
                                    updateStress={this.updateStress}
                                    startEpisodeTwo={this.startEpisodeTwo}
                                    toggleMusic={this.toggleMusic}
                                    shuffleOptions={this.shuffleOptions}
                                    prize={this.state.prize}
                                    specialTag={this.state.specialTag} />}

                            {this.state.episode2 &&
                                <EpisodeTwo
                                    tShirts={this.state.tShirts}
                                    stress={this.state.stress}
                                    username={this.state.username}
                                    sessionId={this.state.sessionId}
                                    updateStress={this.updateStress}
                                    saveScoreToDb={this.saveScoreToDb}
                                    userPlace={this.state.userPlace}
                                    shuffleOptions={this.shuffleOptions}
                                    prize={this.state.prize}
                                    specialTag={this.state.specialTag} />}
                        </div>
                    </ErrorBoundary>
                </div>

                <div className="mobile">
                    <MobileScreen />
                </div>
            </>
        );
    }
}

export default withRouter(App);
