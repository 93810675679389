import React, { Component } from 'react'

export default class Stress extends Component {

    state = {
        stress: ""
    }

    componentDidMount = () => {
        this.setState({
            stress: this.props.stress
        })
        this.stressIncrease();
    }

    stressIncrease = () => {
        let stressGrow = setInterval(() => {
            if (this.props.question.id === "WIN3" || this.props.question.id === "2-END") {
                clearInterval(stressGrow);
                // return stress value to App component
                this.props.updateStress(this.state.stress, this.props.question.id);
                }
            let timeFactor = this.props.question.timefactor;
            let newStress = this.state.stress + 1*timeFactor;
            this.setState({
                stress: newStress
            });
            // set local storage var
            // localStorage.setItem('stress', newStress);

        }, 1000);
    };

    render() {
        return (
            <span className="stress-points">{this.state.stress}</span>
        )
    }
}
