import React from "react";

import ON from '../graphics/volume-on.svg';
import OFF from '../graphics/volume-off.svg';
// import MUSIC from '../music/47_short3_8-bits-racing-car-game-theme_0060.mp3';


// const useAudio = url => {
//   const [audio] = useState(new Audio(url));
//   const [playing, setPlaying] = useState(false);

//   const toggle = () => setPlaying(!playing);

//   useEffect(() => {
//       playing ? audio.play() : audio.pause();
//       audio.loop = true;
//     },
//     [playing]
//   );

//   useEffect(() => {
//     audio.addEventListener('ended', () => setPlaying(false));
//     return () => {
//       audio.removeEventListener('ended', () => setPlaying(false));
//     };
//   }, []);

//   return [props.music, props.toggleMusic];
// };

const MusicOn = () => {
  return (
    <>
      <img id="sound-btn" src={ON} alt="sound on" />
      <span> ON</span>
    </>
  )
}

const MusicOff = () => {
  return (
    <>
      <img id="sound-btn" src={OFF} alt="sound on" />
      <span> OFF</span>
    </>
  )
}

const Player = (props) => {
  // const [playing, toggle] = useAudio(MUSIC);

  return (
    <div>
        <div onClick={()=>{props.toggleMusic()}}>
                     {props.music ? MusicOn() : MusicOff()}
                </div>
      {/* <button onClick={toggle}>{playing ? "Pause" : "Play"}</button> */}
    </div>
  );
};

export default Player;