import React from 'react'

import Logo from "../graphics/logo_full-color.png";
import { ImgWithFallback } from '../components/ImgWithFallback';

import BACKGROUND_webp from "../graphics/background_blank.webp";
import BACKGROUND_jpg from "../graphics/background_blank.jpg";

export default function GameIsOver() {
    return (
        <div>
            <div className="landing-background">
                <div className="episode-backgroung-img-cont">
                    <ImgWithFallback src={BACKGROUND_webp} fallback={BACKGROUND_jpg} alt="" />
                </div>
                <div className="central-block2">
                    <div className="h-100 d-flex justify-content-center align-items-center">
                        <div>
                            <div className="d-flex justify-content-center">
                                <img id="logo-gameover" src={Logo} alt="" />
                            </div>
                            <div className="w-50 d-flex justify-content-center mt-3 m-auto text-center" >
                                <span>This quest ended, but there will be more. Learn more about Splunks Observability Cloud <a href="https://www.splunk.com/en_us/form/beginners-guide-to-observability.html">here</a></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
