import React from 'react';
import { ScoreTable } from './ScoreTable';
import { Event } from "./Tracking";
import axios from 'axios';


import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";

import FBIcon from '../graphics/facebook-icon.svg';
import TwittIcon from '../graphics/twitter-icon.svg';
import LinkIcon from '../graphics/linkedin-icon.svg';
import CONGRATS from '../graphics/congrats.svg';


// const gameText = require('../gameSetup.json');


const SocialMediaButtons = (props) => {



    return (
        <>
            <span onClick={() => { Event(`Marketing${props.specialTag}`, `User shared via Facebook${props.specialTag}`); }}>
                <FacebookShareButton
                    url={"https://o11yquest.splunk.com"}
                    quote={props.socilaMediaText}
                    hashtag="#splunk"
                >
                    <img className="social-icon" src={FBIcon} alt="" />

                </FacebookShareButton>
            </span>
            <span onClick={() => { Event(`Marketing${props.specialTag}`, `User shared via Linkedin${props.specialTag}`); }}>

                <LinkedinShareButton
                    url={"https://o11yquest.splunk.com/"}
                    quote={props.socilaMediaText}
                    hashtag="#splunk"
                    title="Play it with me!"
                    summary={props.socilaMediaText}
                    source={props.source}
                >
                    {/* <LinkedinIcon size={56} /> */}
                    <img className="social-icon" src={LinkIcon} alt="" />

                </LinkedinShareButton>
            </span>

            <span onClick={() => { Event(`Marketing${props.specialTag}`, `User shared via Twitter${props.specialTag}`); }}>

                <TwitterShareButton
                    url={props.socilaMediaText}
                    quote="O11y Quest is so much fun!"
                    hashtag="#splunk"
                >
                    {/* <TwitterIcon size={56} /> */}
                    <img className="social-icon" src={TwittIcon} alt="" />

                </TwitterShareButton>
            </span>

        </>
    );
}

const clamePrize = (sessionId, prize, specialTag) => {
    if (prize) {
        Event(`Marketing${specialTag}`, `User opened PRIZE FORM${specialTag}`);

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': process.env.REACT_APP_API_KEY
        };
        // console.log(sessionId);
        axios
            .put(`api/v1/prizes/?sessionId=${sessionId}`, {}, { headers: headers })
            .then((res) => {
                // console.log(res.data);
            })
    } else {
        Event(`Marketing${specialTag}`, `User clicked BEGINNER'S GUIDE${specialTag}`);

    }

}


export const Finish = (props) => {

    let gameText = '';
    let finalText = '';
    let socilaMediaText = '';
    let mainLink = ''
    let mainLinkText = ''


    if (props.prize) {
        gameText = require('../gameSetupPrize.json');
        mainLink = `https://events.splunk.com/quest-for-O11y-game?sessionid=${props.sessionId}&gamehandle=${props.username}#register`;
        mainLinkText = 'Submit Entry Here';
    } else {
        gameText = require('../gameSetupFree.json');
        mainLink = `https://response.splunk.com/Observability-Interactive-Game-Gen`
        mainLinkText = 'Get more O11y Resources!';
    }

    if (props.tShirts) {
        socilaMediaText = gameText['social-media1'];
        finalText = gameText['final1'];
    } else {
        socilaMediaText = gameText['social-media2'];
        finalText = gameText['final2'];
    }

    return (
        <>
            <div className="container-sm">
                <div className="row">
                    <div className="col-md-7 mb-3 mt-3">
                        {/* <h4>Congrats, {props.username}! </h4> */}
                        <img src={CONGRATS} alt="" />

                        <div className="row justify-content-md-center">
                            <p>You finished with a stress level of <span className="highlight">{props.stress}</span> and got <span className="highlight">{props.userPlace}</span> place as <span className="highlight">{props.username}</span>!  {finalText}</p>
                            {/* {props.tShirts ? <p>{gameText.final1}</p> : <p>{gameText.final2}</p>} */}
                        </div>
                        <div className="d-flex justify-content-center mt-0">
                            <a id="prize" onClick={() => {
                                clamePrize(props.sessionId, props.prize, props.specialTag)
                            }}
                                href={mainLink}
                                target="_blank"
                                rel="noreferrer">&gt; &gt; {mainLinkText} &lt; &lt;
                            </a>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <p>Share your success with everybody</p>

                        </div>
                        <div className="d-flex justify-content-center">

                            <SocialMediaButtons
                                socilaMediaText={socilaMediaText}
                                source="https://o11yquest.splunk.com/"
                                score={props.stress}
                                specialTag={props.specialTag} />
                        </div>

                    </div>

                    <div className="col-lg-5">
                        {props.username && <>
                            <div className="mt-2">
                                <h5>25 Most Chill SREs</h5>
                                <div className="score-table">
                                    <div className="scroll">
                                        <ScoreTable />
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>

        </>
    )
}