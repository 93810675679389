import React from 'react'

export const ImgWithFallback = ({
    src,
    fallback,
    imgClass,
    imgId,
    type = 'image/webp',
    ...delegated
  }) => {
    return (
      <picture>
        <source srcSet={src} type={type} />
        <img id={imgId} className={imgClass} src={fallback} {...delegated} />
      </picture>
    );
  };